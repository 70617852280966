<template>
  <div class="pt-1 -mt-4">
    <div class="w-75 d-flex flex-row space-x-3 float-right mb-1 px-1">
      <v-select
        key="warehouse_id"
        v-model="partnerList"
        placeholder="Pilih Mitra Gudang"
        :options="warehouse"
        :clearable="false"
        label="warehouse_name"
        aria-placeholder="Pilih Mitra Gudang"
        class="w-75"
        :reduce="(option) => option.warehouse_id"
      >
        <template
          slot="option"
          slot-scope="option"
        >
          {{ option.warehouse_name }}
          <span
            v-if="option.unsubscribe === 1"
            class="ml-1 unsubscribe-tag"
          >Unsubscribe</span>
        </template>
      </v-select>
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        class="w-25"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'left'"
      >
        <template
          v-slot:input="picker"
          style="min-width: 350px"
        >
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="
                  formatDateRange(picker.startDate) === formatDateRange(today) && formatDateRange(picker.endDate) === formatDateRange(today)
                "
                style="color: #828282 !important"
              >
                Hari ini
              </span>
              <span
                v-else-if="
                  formatDateRange(picker.startDate) === formatDateRange(last7)
                "
                style="color: #828282 !important"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="
                  formatDateRange(picker.startDate) === formatDateRange(last30)
                "
                style="color: #828282 !important"
              >
                30 Hari Terakhir
              </span>
              <span
                v-else-if="
                  formatDateRange(picker.startDate) === formatDateRange(kompackDate) || formatDateRange(picker.endDate) === formatDateRange(today)
                "
                style="color: #828282 !important"
              >
                Semua Tanggal
              </span>
              <span
                v-else-if="
                  formatDateRange(picker.startDate) === formatDateRange(firstDateOfMonth) && formatDateRange(picker.endDate) === formatDateRange(lastDateOfMonth)
                "
                style="color: #828282 !important"
              >
                Bulan ini
              </span>
              <span
                v-else
                style="color: #828282 !important"
              > Custom </span>
            </div>
            <div class="padding-arrow">
              <b-img src="@/assets/images/icons/arrow-filter.svg" />
            </div>
          </div>
        </template>
      </DateRangePicker>
      <!-- <div class="">
        date
      </div> -->
    </div>
    <div class="">
      <b-overlay
        variant="light"
        :show="loading"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
        class="top-36"
      />
      <b-table
        id="table"
        small
        class="text-center"
        :fields="fields"
        :items="items"
        responsive="sm"
        empty-text="Tidak ada data untuk ditampilkan."
        show-empty
        :busy="loading"
      >
        <template #cell(tanggal_pengajuan)="data">
          {{ formatDate(data.item.submission_date) }}
        </template>

        <template #cell(nama_gudang)="data">
          <div class="d-flex flex-row items-center">
            <div class="mr-1">
              <b-avatar
                :src="data.item.warehouse_logo_url"
              />
            </div>
            <div class="d-flex flex-column items-start py-1">
              <div class="font-medium">
                {{ data.item.warehouse_name }}
              </div>
              <div class="">
                {{ data.item.warehouse_city }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(jumlah_produk)="data">
          <div class="">
            {{ data.item.total_product }}
          </div>
        </template>
        <template #cell(status)="data">
          <div :class="statusColor(data.item.status)">
            {{ data.item.status }}
          </div>
        </template>
        <template #cell(action)="data">
          <div
            class=""
            style="color: #08A0F7;"
            @click="handleDetail(data.item)"
          >
            <button class="outline-none">
              <u>Lihat Detail</u>
            </button>
          </div>
        </template>
      </b-table>
      <div class="hidden">
        <b-button
          variant="primary"
          class="rounded-pill p-1"
        >
          <feather-icon
            icon="PlusIcon"
            size="35"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import {
  today, last7, last30, firstDateOfMonth, lastDateOfMonth, kompackDate,
} from '@/store/helpers'
import DateRangePicker from 'vue2-daterange-picker'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  name: 'RiwayatInbound',
  components: { DateRangePicker, vSelect },
  data() {
    return {

      // init state
      warehouse: [
        {
          warehouse_id: '',
          warehouse_name: 'Semua Gudang',
        },
      ],

      loading: false,

      limit: 50,
      offset: 0,
      lastData: false,

      // filter
      partnerList: '',
      dateRange: {
        startDate: kompackDate,
        endDate: today,
      },

      // for date range
      kompackDate,
      today,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      ranges: {
        'Hari ini': [today, today],
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan ini': [firstDateOfMonth, lastDateOfMonth],
        'Semua ': [kompackDate, today],
      },

      options: [
        {
          value: '',
          text: 'Semua Gudang',
        },
      ],
      dateList: [
        {
          value: '',
          text: 'Tanggal',
        },
      ],

      fields: [
        {
          key: 'tanggal_pengajuan',
          label: 'Tanggal Pengajuan',
          tdClass: 'text-black',
          thClass: 'text-black bg-white text-capitalize font-semibold',
        },
        {
          key: 'nama_gudang',
          label: 'Nama Gudang',
          tdClass: 'text-black',
          thClass: 'text-black bg-white text-capitalize font-semibold',
        },
        {
          key: 'jumlah_produk',
          label: 'Jumlah Produk',
          tdClass: 'text-black',
          thClass: 'text-black bg-white text-capitalize font-semibold',
        },
        {
          key: 'status',
          label: 'Status',
          tdClass: 'text-black',
          thClass: 'text-black bg-white text-capitalize font-semibold',
        },
        {
          key: 'action',
          label: 'Action',
          tdClass: 'text-black',
          thClass: 'text-black bg-white text-capitalize font-semibold',
        },
      ],
      items: [],
    }
  },

  computed: {
    ...mapState('riwayatPengajuan', ['addProduct', 'listWarehouses']),
  },

  watch: {
    dateRange: {
      handler() {
        this.fetchRiwayatAddProduct()
      },
    },
    partnerList: {
      handler() {
        this.fetchRiwayatAddProduct()
      },
    },
  },

  created() {
    this.fetchRiwayatAddProduct()
    this.fetchListWarehouses()
  },

  mounted() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loading) {
        this.fetchNextRiwayatAddProduct()
      }
    }
    this.$router.replace({ query: { tab: 'riwayat-tambah-produk' } })
  },

  methods: {

    fetchRiwayatAddProduct() {
      this.offset = 0
      this.loading = true
      this.$store
        .dispatch('riwayatPengajuan/getListAddProduct', {
          start_date: this.formatDateRange(this.dateRange.startDate),
          end_date: this.formatDateRange(this.dateRange.endDate),
          warehouse_id: this.partnerList,
          limit: this.limit,
          offset: this.offset,
        })
        .then(() => {
          this.items = this.addProduct
          this.loading = false
          this.offset = this.addProduct.length
          if (this.addProduct.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    fetchNextRiwayatAddProduct() {
      if (!this.lastData) {
        this.loading = true
        this.$store
          .dispatch('riwayatPengajuan/getListAddProduct', {
            start_date: this.formatDateRange(this.dateRange.startDate),
            end_date: this.formatDateRange(this.dateRange.endDate),
            warehouse_id: this.partnerList,
            limit: this.limit,
            offset: this.offset,
          })
          .then(() => {
            this.items.push(...this.addProduct)
            this.loading = false
            this.offset += this.addProduct.length
            if (this.addProduct.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'AlertCircleIcon',
                  text: 'Gagal load data, silahkan coba lagi',
                  variant: 'danger',
                },
              },
              2000,
            )
          })
      }
    },

    fetchListWarehouses() {
      this.$store
        .dispatch('riwayatPengajuan/getListWarehouses')
        .then(() => {
          const warehouse = this.warehouse.concat(this.listWarehouses.map(data => ({
            warehouse_id: data.warehouse_id,
            warehouse_name: data.warehouse_name,
            unsubscribe: data.unsubscribe,
          })))

          this.warehouse = warehouse
        })
        .catch(() => {
          this.loading = false
        })
    },

    // detail(data) {
    //   const { id } = data

    //   this.$router.push({
    //     path: `/detail-riwayat-inbound/${id}`,
    //   })
    //   localStorage.setItem('detailInbound', JSON.stringify(data))
    // },

    handleDetail(data) {
      const { id } = data
      this.$router.push({
        path: `/detail-riwayat-tambah-product/${id}`,
      })
    },

    statusColor(status) {
      if (status === 'Sedang Diajukan') {
        return 'text-warning'
      } if (status === 'Disetujui') {
        return 'text-success'
      }
      return 'text-danger'
    },
    formatDate(value) {
      return moment(value).format('DD MMMM YYYY')
    },

    formatDateRange(value) {
      return moment(value).format('YYYY-MM-DD')
    },
  },
}
</script>
<style lang="scss">
   @import '@core/scss/vue/libs/vue-select.scss';
 </style>

<style scoped>
.padding-arrow {
  padding-top: 5px;
  padding-bottom: 5px;
}

.unsubscribe-tag {
  border-radius: 4px;
  border: 1px solid currentColor;
  background: transparent;

  padding: 2px 6px;
  color: currentColor;
}
</style>
